/**
 * 此文件存储所有关于余料的公有处理方法，强关联性的需要避免在此文件声明
 */
import { fetchSurplusByTexture } from '@/apis/paiban'
import type { PlankTexture } from '@/partTypes'
import { isPlankTypeSame } from '@/util/plankCommonFuncs'

export interface ISurplus {
  id: number
  // 材质
  type: string
  width: number
  long: number
  uid: number
  // 颜色
  color: string
  create_time: string
  update_time: string
  ggid: string
  // 厚度
  thick: string
  area: number
  amount: string
  name: string
  shape: 1 | 0
  min_width: number
  min_long: number
  branch_no: string
  from_address: string
  from_order_num: string
  user_num: string
  remark: string
  branch_name: string | null
  spec: string
}

/**
 * @description 根据传入的板件查询相对应材质颜色厚度的余料信息
 * @param payload 携带颜色材质厚度的数据
 */
export async function searchSurplusByTexture(payload: PlankTexture[]) {
  try {
    // 收集所有的板材类型
    const matchList = payload.reduce((acc, cur) => {
      const target = acc.find((item) => isPlankTypeSame(item, cur))
      !target &&
        acc.push({
          matCode: cur.matCode,
          thick: cur.thick,
          texture: cur.texture,
        })
      return acc
    }, [] as PlankTexture[])
    const result = await fetchSurplusByTexture(matchList)
    if (result.status !== 1) {
      return {
        err: 1,
        data: [],
      }
    }
    return {
      err: 0,
      data: [],
      ...(result?.data ?? {}),
    }
  } catch (error) {
    return {
      err: 1,
      data: [],
    }
  }
}

/**
 * @description 根据id在提供的余料数组中筛选出对应的余料
 * @param id 余料id
 * @param surplus 接口余料数据
 */
export function filterSurplusById(id: number | number[], surplus: ISurplus[]) {
  const ids = Array.isArray(id) ? id : [id]
  return (surplus ?? []).filter((item) => ids.includes(item.id))
}

/**
 * @description 生成以颜色材质厚度为key的余料对象
 * @param surplus 余料数组数据
 */
export function groupSurplusParams(surplus: ISurplus[]) {
  return (surplus ?? []).reduce((acc, cur) => {
    const key = `${cur.color}:${cur.type}:${cur.thick}`
    if (key in acc) {
      acc[key].push(cur)
    } else {
      acc[key] = [cur]
    }
    return acc
  }, {} as Record<string, ISurplus[]>)
}
